import React from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import HTML from "../components/HTML"
import FleetForm from "../components/fleetForm/FleetForm"

function Fleet(props) {
  const { pageContext } = props
  const { pageContent, theme,dealerInfo,inventorymanagement,lead_url,site_id } = pageContext
  const dealerHours = dealerInfo
  const dealerLoc = dealerInfo
  const document = typeof window !== 'undefined' && window.document

  console.log(pageContent)

  let components = [
    <FleetForm data={pageContent.FleetForm} leadURL={pageContext.lead_url} site_id={pageContext.site_id} pageSource='FleetForm' pageURL={document.URL} />,
    <SEO key={"SEO"} title={"Fleet"} data={pageContent.SEO} />
  ]

  if(pageContent.hasOwnProperty("arrayRawHTML")) {
    const { contents } = pageContent.arrayRawHTML
    console.log(contents)
    const rawComponents = contents.value.map(element => {
        return <HTML data={{ 
            order: Number(element.order),
            visible: element.visible,
          rawHTML: element.rawHtml
        }} key={"HTML"} />
    })
    components.push(...rawComponents)
}

  let sortedComponents = components.sort((a, b) =>
     parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })

  return (
    <Layout>
      { visibleComponents}
    </Layout>
  )
}

export default Fleet
