import { useLocation } from "@reach/router";

export default function useDataLayer() {
  const location = useLocation()

  const pushToDataLayer = (event, response) => {
    console.log(window.dataLayer)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event,
        page: {
          url: location.href,
          response
        }
      })
    }
  }

  return pushToDataLayer
}